body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #323232;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-thumb {
  background-color: #636363;
  border-radius: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #FFF;
  border-radius: 5px;
}
